import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { Link } from './ui';
import { ProductPrice } from './Prices';

const StyledProductCard = styled.article`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const StyledImageWrapper = styled(Link)`
  position: relative;
  display: block;
`;

const StyledImage = styled(GatsbyImage)`
  display: block;
`;

const StyledLabel = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 12px;
  color: ${standardColours.white};
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fontSize(14)};
  background-color: ${brandColours.quaternary};

  ${minBreakpointQuery.small`
    ${fontSize(16)};
  `}

  ${minBreakpointQuery.large`
    padding: 10px 16px;
  `}
`;

const StyledHeading = styled.h3`
  margin-top: 20px;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fontSize(15)};

  ${minBreakpointQuery.small`
    margin-top: 25px;
    ${fontSize(17)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    ${fontSize(19)};
  `}
`;

const StyledPrice = styled(ProductPrice)`
  margin-top: 12px;
  ${fontSize(14)};

  ${minBreakpointQuery.small`
    margin-top: 16px;
    ${fontSize(16)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
    ${fontSize(18)};
  `}
`;

const ProductCard = ({
  data: {
    title,
    handle,
    featuredImage,
    totalInventory,
    tracksInventory,
    priceRange,
    variants,
  },
}) => {
  const urlPath = `shop/${handle}`;

  return (
    <StyledProductCard>
      {featuredImage && (
        <StyledImageWrapper to={urlPath}>
          <StyledImage
            image={featuredImage.gatsbyImageData}
            alt={featuredImage.altText || title}
          />
          {!totalInventory && tracksInventory && (
            <StyledLabel>Temporarily out of stock</StyledLabel>
          )}
        </StyledImageWrapper>
      )}
      <StyledHeading>
        <Link to={urlPath}>{title}</Link>
      </StyledHeading>
      <StyledPrice priceRange={priceRange} variants={variants} />
    </StyledProductCard>
  );
};

export default ProductCard;

export const ProductCardFragment = graphql`
  fragment ProductCardFragment on ShopifyProduct {
    id
    title
    handle
    featuredImage {
      gatsbyImageData(width: 290, height: 360)
      altText
    }
    metafields {
      key
      value
    }
    totalInventory
    tracksInventory
    priceRange: priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    variants {
      price
      compareAtPrice
    }
  }
`;
