import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
} from '../styles';
import { Container, Heading, Button } from './ui';
import IconCta from './IconCta';

const StyledIconCtas = styled.section`
  ${sectionPaddings(undefined, '70px')};
  text-align: center;
  background-color: ${({ isProductPage }) =>
    isProductPage ? standardColours.lighterGrey : brandColours.primary};
`;

const StyledItems = styled.div`
  margin: 0 -10px;

  ${minBreakpointQuery.tiny`
    display: flex;
    justify-content: space-evenly;
  `}

  ${maxBreakpointQuery.large`
    flex-wrap: wrap;
  `}

  ${minBreakpointQuery.large`
    margin-top: 10px;
    margin-bottom: 10px;
  `}
`;

const StyledItem = styled(IconCta)`
  padding: 15px 10px;

  ${minBreakpointQuery.tiny`
    width: 50%;
  `}

  ${minBreakpointQuery.smedium`
    width: calc(100% / 3);
  `}

  ${minBreakpointQuery.mlarge`
    width: calc(25%);
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 25px;

  ${minBreakpointQuery.tsmall`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 35px;
  `}

  ${({ isProductPage }) => {
    if (isProductPage) {
      return css`
        color: ${standardColours.grey};

        svg {
          fill: ${standardColours.grey};
          border-color: ${standardColours.grey};
        }
      `;
    }
  }}
`;

const IconCtas = ({ heading, items, link, isProductPage }) => {
  const { datoCmsIconCtasSitewide } = useStaticQuery(graphql`
    query IconCtasQuery {
      datoCmsIconCtasSitewide {
        heading
        iconCtas {
          id
          icon {
            url
            alt
          }
          heading
          text
        }
        link {
          text
          page {
            ...LinkFragment
          }
        }
      }
    }
  `);

  heading = heading || datoCmsIconCtasSitewide.heading;
  items = items || datoCmsIconCtasSitewide.iconCtas;
  link = link || datoCmsIconCtasSitewide.link;

  return (
    <StyledIconCtas isProductPage={isProductPage}>
      <Container>
        <Heading colour={!isProductPage && standardColours.white}>
          {heading}
        </Heading>
        <StyledItems>
          {items.map(item => (
            <StyledItem
              key={item.id}
              data={item}
              isProductPage={isProductPage}
            />
          ))}
        </StyledItems>
        <StyledButton
          alt={true}
          to={link.page.slug}
          linkData={link.page}
          isProductPage={isProductPage}
        >
          {link.text}
        </StyledButton>
      </Container>
    </StyledIconCtas>
  );
};

export default IconCtas;
