import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';

const StyledIconCta = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
`;

const StyledIcon = styled.img`
  max-width: 50px;
`;

const StyledContent = styled.div`
  margin-left: 15px;

  ${({ isProductPage }) => {
    if (!isProductPage) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}
`;

const StyledHeading = styled.h3`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  ${fontSize(16)};

  ${minBreakpointQuery.large`
    ${fontSize(18)};
  `}
`;

const StyledText = styled.p`
  margin-top: 6px;
  ${fontSize(14)};
  line-height: 1.5;

  ${minBreakpointQuery.large`
    margin-top: 8px;
    ${fontSize(16)};
  `}
`;

const IconCta = ({
  data: { icon, heading, text },
  isProductPage,
  ...props
}) => (
  <StyledIconCta {...props}>
    <StyledIcon src={icon.url} alt={icon.alt} loading="lazy" />
    <StyledContent isProductPage={isProductPage}>
      <StyledHeading>{heading}</StyledHeading>
      <StyledText>{text}</StyledText>
    </StyledContent>
  </StyledIconCta>
);

export default IconCta;
