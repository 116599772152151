import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, visuallyHidden } from '../styles';
import { Svg } from './ui';
import starIcon from '../images/star-icon.svg';

const StyledStars = styled.span`
  white-space: nowrap;
`;

const StyledText = styled.span`
  ${visuallyHidden()};
`;

const StyledSvg = styled(Svg)`
  display: inline-block;
  margin-left: 2px;
  width: 18px;
  height: 18px;

  ${minBreakpointQuery.large`
    margin-left: 3px;
    width: 20px;
    height: 20px;
  `}

  &:first-child {
    margin-left: 0;
  }
`;

const Stars = ({ rating, ...props }) => (
  <StyledStars {...props}>
    <StyledText>{rating} Stars</StyledText>
    {[...Array(rating)].map((item, id) => (
      <StyledSvg key={id} image={starIcon} />
    ))}
  </StyledStars>
);

export default Stars;
