import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Svg } from './ui';
import { formatPrice } from '../utils';
import plantIcon from '../images/plant-icon.svg';

const StyledPotUpsells = styled.section`
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid ${standardColours.lightGrey};
`;

const StyledGroup = styled.fieldset`
  margin: 20px 0 0;
  padding: 0;
  border: 0;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledHeading = styled.legend`
  margin-bottom: 15px;
  padding: 0;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  line-height: 1.1;
`;

const StyledItems = styled.div`
  display: grid;
  gap: 15px;

  ${({ isColour }) => {
    if (isColour) {
      return css`
        grid-template-columns: repeat(auto-fit, 38px);
      `;
    } else {
      return css`
        grid-template-columns: repeat(2, 1fr);

        ${minBreakpointQuery.tsmall`
          grid-template-columns: repeat(3, 1fr);
        `}

        ${minBreakpointQuery.smedium`
          grid-template-columns: repeat(2, 1fr);
        `}

        ${minBreakpointQuery.mlarge`
          grid-template-columns: repeat(3, 1fr);
        `}
      `;
    }
  }}
`;

const StyledItem = styled.label``;

// moved up for checked styling
const StyledPotType = styled.span`
  display: block;
  padding: 12px 15px;
  height: 100%;
  border: 1px ${standardColours.lightGrey} solid;
  cursor: pointer;
  ${standardTransition('border-color')};
`;

const StyledPotColour = styled.span`
  display: block;
  padding: 6px;
  border: 1px ${standardColours.lightGrey} solid;
  border-radius: 50%;
  cursor: pointer;
  ${standardTransition('border-color')};

  &:before {
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    background-color: ${({ colour }) =>
      colour ? colour : standardColours.black};
    border-radius: 50%;
  }
`;

const StyledInput = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledPotType}, &:checked + ${StyledPotColour} {
    border-color: ${standardColours.black};
  }
`;

const StyledName = styled.strong`
  ${({ isColour }) => {
    if (isColour) {
      return css`
        ${visuallyHidden()};
      `;
    } else {
      return css`
        position: relative;
        display: block;
        padding-right: 26px;
        font-weight: ${fontWeights.medium};
      `;
    }
  }}
`;

const StyledIcon = styled(Svg)`
  position: absolute;
  top: 2px;
  right: 0;
  height: 16px;
  width: 16px;
`;

const StyledDescription = styled.span`
  display: block;
  margin-top: 6px;
  ${fontSize(11)};
`;

const PotUpsells = ({
  selectedProductVariant,
  potUpsells,
  selectedPotUpsellVariant,
  setSelectedPotUpsellVariant,
}) => {
  const {
    datoCmsProductSitewide: { colourSwatches },
  } = useStaticQuery(graphql`
    query PotUpsellsQuery {
      datoCmsProductSitewide {
        colourSwatches {
          name
          colour {
            hex
          }
        }
      }
    }
  `);

  const [potTypes, setPotTypes] = useState([]);
  const [selectedPotType, setSelectedPotType] = useState();

  useEffect(() => {
    const plantSize = selectedProductVariant.metafields.find(
      ({ key }) => key === 'plant_size'
    ).value;

    const plantSizePotTypes = potUpsells
      .map(potUpsell => {
        const variants = potUpsell.variants.filter(
          ({ availableForSale, metafields }) =>
            availableForSale &&
            metafields.find(
              ({ key, value }) => key === 'plant_size' && value === plantSize
            )
        );

        return {
          ...potUpsell,
          variants: variants,
        };
      })
      .filter(({ variants }) => variants.length > 0);

    const potTypes = [
      {
        name: 'Nursery Pot',
        description: 'Standard pot, no charge',
      },
      ...plantSizePotTypes,
    ];

    setPotTypes(potTypes);
    setSelectedPotType();
  }, [selectedProductVariant]);

  const handlePotTypeChange = shopifyProduct => {
    const selectedPotType = potTypes.find(
      potType => potType.shopifyProduct === shopifyProduct
    );

    setSelectedPotType(selectedPotType);
    setSelectedPotUpsellVariant(
      selectedPotType.variants && selectedPotType.variants[0]
    );
  };

  const handlePotColourChange = variant => {
    setSelectedPotUpsellVariant(variant);
  };

  return (
    potTypes.length > 0 && (
      <StyledPotUpsells>
        <StyledGroup>
          <StyledHeading>Choose Planter:</StyledHeading>
          <StyledItems>
            {potTypes.map(
              ({ name, description, variants, shopifyProduct }, i) => {
                let minPrice;
                let maxPrice;

                if (variants) {
                  variants.forEach(({ price }) => {
                    if (!minPrice || price < minPrice) {
                      minPrice = price;
                    }

                    if (!maxPrice || price > maxPrice) {
                      maxPrice = price;
                    }
                  });
                }

                return (
                  <StyledItem key={i}>
                    <StyledInput
                      type="radio"
                      name="pot-type"
                      value={name}
                      checked={
                        (selectedPotType &&
                          selectedPotType.shopifyProduct === shopifyProduct) ||
                        i === 0
                      }
                      onChange={e => handlePotTypeChange(shopifyProduct)}
                    />
                    <StyledPotType>
                      <StyledName>
                        {name} <StyledIcon image={plantIcon} />
                      </StyledName>
                      <StyledDescription>
                        {description}
                        {variants && (
                          <>
                            , {minPrice !== maxPrice ? 'from ' : ''}
                            {formatPrice(minPrice)}
                          </>
                        )}
                      </StyledDescription>
                    </StyledPotType>
                  </StyledItem>
                );
              }
            )}
          </StyledItems>
        </StyledGroup>
        {selectedPotType &&
          selectedPotType.variants &&
          selectedPotType.variants.length > 0 && (
            <StyledGroup>
              <StyledHeading>Choose Colour:</StyledHeading>
              <StyledItems isColour={true}>
                {selectedPotType.variants.map((variant, id) => {
                  const colourName = variant.selectedOptions.find(
                    ({ name }) => name === 'Colour'
                  ).value;

                  const hexColour = colourSwatches.find(
                    ({ name }) => name === colourName
                  ).colour.hex;

                  return (
                    <StyledItem key={id}>
                      <StyledInput
                        type="radio"
                        name="pot-colour"
                        value={colourName}
                        checked={selectedPotUpsellVariant === variant}
                        onChange={() => handlePotColourChange(variant)}
                      />
                      <StyledPotColour colour={hexColour}>
                        <StyledName isColour={true}>{colourName}</StyledName>
                      </StyledPotColour>
                    </StyledItem>
                  );
                })}
              </StyledItems>
            </StyledGroup>
          )}
      </StyledPotUpsells>
    )
  );
};

export default PotUpsells;
