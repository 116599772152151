import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import ProductCard from './ProductCard';

const StyledProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  width: 100%;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}

  ${minBreakpointQuery.smedium`
    gap: 30px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}

  ${minBreakpointQuery.mlarge`
    row-gap: 40px;
  `}
`;

const ProductList = ({ items, ...props }) => (
  <StyledProductList {...props}>
    {items.map(item => (
      <ProductCard key={item.id} data={item} />
    ))}
  </StyledProductList>
);

export default ProductList;
