import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, fontWeights } from '../styles';
import OutOfStockForm from './OutOfStockForm';
import { StoreContext } from '../context/StoreContext';

const StyledProductAvailability = styled.div`
  margin-top: 22px;

  ${minBreakpointQuery.large`
    margin-top: 28px;
  `}
`;

const StyledText = styled.p`
  font-weight: ${fontWeights.medium};
  line-height: 1.5;
`;

const ProductAvailability = ({
  storefrontProductId,
  legacyResourceId,
  selectedVariant,
  selectedVariantAvailability,
  setSelectedVariantAvailability,
}) => {
  const { client } = useContext(StoreContext);

  useEffect(() => {
    client.product.fetch(storefrontProductId).then(product => {
      setSelectedVariantAvailability(
        product.variants.find(
          variant => variant.id === selectedVariant.storefrontId
        ).available
      );
    });
  }, [
    client.product,
    storefrontProductId,
    setSelectedVariantAvailability,
    selectedVariant,
  ]);

  if (selectedVariantAvailability === false) {
    return (
      <StyledProductAvailability>
        <StyledText>
          Sorry, this product is temporarily out of stock, however you can
          register your email below to receive an email when this product
          becomes available again.
        </StyledText>
        <OutOfStockForm
          productId={legacyResourceId}
          variantId={selectedVariant.legacyResourceId}
          variantSku={selectedVariant.sku}
        />
      </StyledProductAvailability>
    );
  } else {
    return '';
  }
};

export default ProductAvailability;
