import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { formatPrice } from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledCrossSellProducts = styled.section`
  margin-top: 22px;

  ${minBreakpointQuery.large`
    margin-top: 28px;
  `}
`;

const StyledText = styled.p`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
`;

const StyledItems = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-top: 12px;

  ${minBreakpointQuery.large`
    gap: 15px;
    margin-top: 15px;
  `}
`;

const StyledItem = styled.li``;

const StyledName = styled.span`
  display: block;
  margin-top: 10px;
  font-weight: ${fontWeights.medium};
  ${fontSize(14)};

  ${minBreakpointQuery.large`
    margin-top: 12px;
    ${fontSize(15)};
  `}
`;

const StyledButton = styled.button`
  margin-top: 4px;
  padding: 2px 0;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  ${fontSize(12)};
  text-decoration: underline;
  text-underline-offset: 2px;
  background: none;
  border: none;

  ${minBreakpointQuery.large`
    margin-top: 6px;
  `}
`;

const CrossSellProducts = ({ items }) => {
  const { addProductsToCheckout } = useContext(StoreContext);

  return (
    items.length > 0 && (
      <StyledCrossSellProducts>
        <StyledText>Add these products:</StyledText>
        <StyledItems>
          {items.map(({ title, featuredImage, variants }, id) => {
            const variant = variants[0];

            return (
              <StyledItem key={id}>
                <GatsbyImage
                  image={featuredImage.gatsbyImageData}
                  alt={featuredImage.altText || title}
                />
                <StyledName>{title}</StyledName>
                <StyledButton
                  onClick={async () =>
                    await addProductsToCheckout({
                      variantId: variant.storefrontId,
                      quantity: 1,
                    })
                  }
                >
                  Add {formatPrice(variant.price)}
                </StyledButton>
              </StyledItem>
            );
          })}
        </StyledItems>
      </StyledCrossSellProducts>
    )
  );
};

export default CrossSellProducts;
