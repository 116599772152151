import React, { useState } from 'react';
import styled from 'styled-components';
import {
  commonFormElementStyles,
  commonFormButtonStyles,
  visuallyHidden,
} from '../styles';
import { dataLayer } from '../utils';

const StyledOutOfStockForm = styled.form``;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 15px 0;
`;

const StyledLabelText = styled.span`
  ${visuallyHidden()};
`;

const StyledTextInput = styled.input`
  ${commonFormElementStyles()};
`;

const StyledCheckboxInput = styled.input`
  margin-right: 10px;
`;

const StyledButton = styled.button`
  ${commonFormButtonStyles()};
`;

const OutOfStockForm = ({ productId, variantId, variantSku }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const myForm = document.getElementById('out-of-stock-form');
    const formData = new FormData(myForm);

    fetch('/api/outofstock', {
      method: 'POST',
      body: JSON.stringify(new URLSearchParams(formData).toString()),
    })
      .then(() => {
        setFormSubmitted(true);
        dataLayer('outOfStockFormSubmit', { variantSku: variantSku });
        myForm.reset();
      })
      .catch(error => alert(error));
  };

  return (
    <StyledOutOfStockForm
      id="out-of-stock-form"
      method="POST"
      onSubmit={e => handleSubmit(e)}
    >
      <input type="hidden" name="product-number" value={productId} />
      <input type="hidden" name="quantity-required" value="1" />
      <input type="hidden" name="utc-offset" value="3600" />
      <input type="hidden" name="variant-number" value={variantId} />
      <StyledLabel>
        <StyledLabelText>Email</StyledLabelText>
        <StyledTextInput
          type="email"
          name="email"
          placeholder="Email"
          required
          onFocus={() => setFormSubmitted(false)}
        />
      </StyledLabel>
      <StyledLabel for="accepts-marketing">
        <StyledCheckboxInput
          type="checkbox"
          id="accepts-marketing"
          name="accepts-marketing"
        />
        Keep me up to date on news and exclusive offers
      </StyledLabel>
      <StyledButton disabled={formSubmitted}>
        {!formSubmitted ? 'Notify me' : 'Thanks'}
      </StyledButton>
    </StyledOutOfStockForm>
  );
};

export default OutOfStockForm;
