import React, { useState } from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledAccordion = styled.section`
  ${sectionMargins(undefined, '80px')};
`;

const StyledHeading = styled(Heading)`
  ${({ hideHeading }) => {
    if (hideHeading) {
      return css`
        ${visuallyHidden()};
      `;
    } else {
      return css`
        margin-bottom: 20px;

        ${minBreakpointQuery.small`
          margin-bottom: 30px;
        `}

        ${minBreakpointQuery.large`
          margin-bottom: 40px;
        `}
      `;
    }
  }}
`;

const StyledItems = styled.div`
  border-top: 1px solid ${standardColours.lightGrey};
`;

const StyledItem = styled.article`
  padding: 20px 0;
  border-bottom: 1px solid ${standardColours.lightGrey};
`;

const StyledSubHeading = styled.h3`
  position: relative;
  padding-right: 30px;
  font-weight: ${fontWeights.bold};
  ${fontSize(20)};
  line-height: 1.5;
  cursor: pointer;

  ${minBreakpointQuery.small`
    padding-right: 35px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    padding-right: 40px;
    ${fontSize(24)};
  `}

  &:after {
    content: ${({ display }) => (display ? `'-'` : `'+'`)};
    position: absolute;
    top: 50%;
    right: 0;
    color: ${brandColours.primary};
    font-weight: ${fontWeights.light};
    ${fontSize(30)};
    transform: translateY(-50%);

    ${minBreakpointQuery.small`
      ${fontSize(35)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(40)};
    `}
  }

  span {
    padding-bottom: 1px;
    border-bottom: 1px solid ${standardColours.grey};
  }
`;

const StyledContent = styled(HtmlContent)`
  display: ${({ display }) => (display ? 'block' : 'none')};
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const Accordion = ({
  heading,
  hideHeading,
  items,
  addFaqsStructuredData,
  isProductPage,
}) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <>
      {addFaqsStructuredData && (
        <HelmetDatoCms
          script={[
            {
              type: 'application/ld+json',
              innerHTML: `{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                ${items.map(({ heading, content }) =>
                  JSON.stringify({
                    '@type': 'Question',
                    name: heading,
                    acceptedAnswer: {
                      '@type': 'Answer',
                      text: content,
                    },
                  })
                )}
              ]
            }`,
            },
          ]}
        />
      )}
      <StyledAccordion {...(isProductPage ? { id: 'product-info' } : {})}>
        <Container>
          <StyledHeading removeWave={true} hideHeading={hideHeading}>
            {heading}
          </StyledHeading>
          <StyledItems>
            {items.map(({ id, heading, content }, i) => {
              const display = activeItem === i;

              return (
                <StyledItem key={id || `accordion-item-${i}`}>
                  <StyledSubHeading
                    display={display}
                    onClick={() => setActiveItem(display ? undefined : i)}
                  >
                    <span>{heading}</span>
                  </StyledSubHeading>
                  <StyledContent html={content} display={display} />
                </StyledItem>
              );
            })}
          </StyledItems>
        </Container>
      </StyledAccordion>
    </>
  );
};

export default Accordion;
