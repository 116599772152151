import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container, Heading } from './ui';
import ProductList from './ProductList';

const StyledRelatedProducts = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  text-align: center;
`;

const RelatedProducts = ({ items }) =>
  items.length > 0 && (
    <StyledRelatedProducts>
      <Container>
        <StyledHeading>You may also like these</StyledHeading>
        <ProductList items={items} />
      </Container>
    </StyledRelatedProducts>
  );

export default RelatedProducts;
